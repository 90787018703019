<template>
    <div>
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-2">
                                    Webentwickler gesucht?
                                </h1>
                                <vue-typed-js
                                    :strings="[
                                        'Idee.',
                                        'Webseite.',
                                        'App.',
                                        'Erweiterung.',
                                    ]"
                                    :loop="true"
                                >
                                    <h2 class="text-white d-block h-25">
                                        Wir unterstützen Dich bei
                                        <span class="d-block">
                                            Deiner
                                            <span
                                                class="typing text-danger"
                                            ></span>
                                        </span>
                                    </h2>
                                </vue-typed-js>

                                <div class="lead">
                                    <h2 class="display-4">
                                        Sind wir die richtigen für Dein Projekt?
                                    </h2>
                                    <p class="text-white">
                                        Seit mehr als 10 Jahren entwickeln wir
                                        Webseiten für kleine, große, nationale
                                        und internationale Unternehmen.
                                    </p>
                                </div>

                                <div
                                    class="btn-wrapper d-flex flex-wrap-reverse flex-md-nowrap"
                                >
                                    <base-button
                                        tag="a"
                                        href="#features"
                                        class="mb-3 w-100"
                                        type="white"
                                        icon="fa fa-arrow-down"
                                    >
                                        mehr entdecken
                                    </base-button>

                                    <base-button
                                        tag="a"
                                        class="mb-3 text-white w-100 md-w-auto"
                                        type="info"
                                        icon="fa fa-code"
                                        href="#kontakt"
                                    >
                                        Jetzt Anfragen
                                    </base-button>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <img
                                    src="img/10-jahre.png"
                                    class="mx-auto d-block my-3 img-fluid"
                                    alt="über 10 Jahre Erfahrung"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- 1st Hero Variation -->
        </div>

        <section id="features" class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card
                                    class="border-0"
                                    hover
                                    shadow
                                    body-classes="py-5"
                                >
                                    <icon
                                        name="ni ni-money-coins"
                                        type="success"
                                        rounded
                                        class="mb-4"
                                    ></icon>

                                    <h6 class="text-success text-uppercase">
                                        Full Stack
                                    </h6>

                                    <p class="description mt-3">
                                        Du hast etwas großes vor oder brauchst
                                        eine skalierbare Webseite? Du benötigst
                                        kurzfristig professionellen Support?
                                    </p>

                                    <ul>
                                        <li>
                                            Webanwendungen - von Stack Beratung,
                                            Datenbank Model, bis zur Optimierung
                                            der Live Anwendung
                                        </li>
                                        <li>
                                            Anbindung und Entwicklung von
                                            beliebigen Schnittstellen (API)
                                        </li>
                                        <li>
                                            Schnelle Einarbeitung in bestehende
                                            Projekte
                                        </li>
                                    </ul>

                                    <div>
                                        <badge type="success" rounded>
                                            App
                                        </badge>
                                        <badge type="success" rounded>
                                            PWA
                                        </badge>

                                        <badge type="success" rounded>
                                            Datenbanken
                                        </badge>

                                        <badge type="success" rounded>
                                            Schnittstellen
                                        </badge>
                                        <badge type="success" rounded>
                                            Code Quality
                                        </badge>
                                        <badge type="success" rounded>
                                            Performance
                                        </badge>
                                    </div>

                                    <base-button
                                        tag="a"
                                        href="#Fullstack"
                                        type="success"
                                        class="mt-4"
                                        style="display: none"
                                    >
                                        Learn more
                                    </base-button>
                                </card>
                            </div>

                            <div class="col-lg-4">
                                <card
                                    class="border-0"
                                    hover
                                    shadow
                                    body-classes="py-5"
                                >
                                    <icon
                                        name="fa fa-wordpress"
                                        type="primary"
                                        rounded
                                        class="mb-4"
                                    ></icon>

                                    <h6 class="text-primary text-uppercase">
                                        Wordpress
                                    </h6>

                                    <p class="description mt-3">
                                        <strong>WordPress</strong>
                                        ist das beliebteste System zum verwalten
                                        von Webseiten und das nicht ohne Grund.
                                        Jeder kann es bedienen! Seit über 10
                                        Jahren unterstützen wir Unternehmer und
                                        Unternehmen mit maßgeschneiderten
                                        Lösungen.
                                    </p>

                                    <ul>
                                        <li>Webseiten Entwicklung</li>

                                        <li>Plugin Entwicklung</li>

                                        <li>Wordpress Optimierungen</li>
                                    </ul>

                                    <div>
                                        <badge type="primary" rounded>
                                            Theme
                                        </badge>

                                        <badge type="primary" rounded>
                                            Plugins
                                        </badge>

                                        <badge type="primary" rounded>
                                            Webseite
                                        </badge>
                                        <badge type="primary" rounded>
                                            Page Speed
                                        </badge>
                                        <badge type="primary" rounded>
                                            Wartung
                                        </badge>
                                    </div>

                                    <base-button
                                        tag="a"
                                        href="#Wordpress"
                                        type="primary"
                                        class="mt-4"
                                        style="display: none"
                                    >
                                        Learn more
                                    </base-button>
                                </card>
                            </div>

                            <div class="col-lg-4">
                                <card
                                    class="border-0"
                                    hover
                                    shadow
                                    body-classes="py-5"
                                >
                                    <icon
                                        name="fa fa-cart-plus"
                                        type="warning"
                                        rounded
                                        class="mb-4"
                                    ></icon>

                                    <h6 class="text-warning text-uppercase">
                                        E-Commerce
                                    </h6>

                                    <p class="description mt-3">
                                        Onlineshop Entwicklung und Betreuung.
                                        Wir unterstützen Dich von Deiner Idee
                                        bis zur Optimierung Deiner Verkäufe.
                                    </p>
                                    <ul>
                                        <li>Online Shop Erstellung</li>
                                        <li>Individuelle Anpassung</li>
                                        <li>Online Marketing</li>
                                    </ul>
                                    <div>
                                        <badge type="warning" rounded>
                                            Online Shop
                                        </badge>
                                        <badge type="warning" rounded>
                                            Conversion Optimierung
                                        </badge>
                                        <badge type="warning" rounded>
                                            Suchmaschinen Optimierung
                                        </badge>
                                        <badge type="warning" rounded>
                                            Tracking
                                        </badge>

                                        <badge type="warning" rounded>
                                            Erweiterung
                                        </badge>
                                    </div>

                                    <base-button
                                        tag="a"
                                        href="#Ecommerce"
                                        type="warning"
                                        class="mt-4"
                                        style="display: none"
                                    >
                                        Learn more
                                    </base-button>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Technologien></Technologien>

        <section class="section section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-1 bg-gradient-success shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container py-0">
                <div class="row row-grid align-items-center">
                    <div class="col-lg-6 order-lg-2 ml-lg-auto">
                        <div class="position-relative pl-md-5">
                            <LottieWebDev />
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-1">
                        <div class="d-flex">
                            <div>
                                <icon
                                    name="ni ni-building"
                                    size="lg"
                                    class="bg-gradient-white"
                                    color="primary"
                                    shadow
                                    rounded
                                ></icon>
                            </div>
                            <div class="pl-4">
                                <h4 class="display-3 text-white">
                                    Konstruktive Lösungen, die einfach und
                                    nachhaltig funktionieren.
                                </h4>
                                <p class="text-white">
                                    Damit Du langfristigen Erfolg hast haben wir
                                    es uns zum Ziel gemacht, nachhaltige
                                    Webentwicklung einfach aussehen zu lassen.
                                    Nicht immer muss das Rad neu erfunden werden
                                    und auch ein Relaunch einer Webseite muss
                                    keine komplette Neuprogrammierung sein.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-12 row row-grid align-items-start order-lg-3"
                    >
                        <div class="px-2 mt-5 col-lg-6">
                            <card shadow class="shadow-lg--hover">
                                <div class="d-flex px-3">
                                    <div>
                                        <icon
                                            name="ni ni-planet"
                                            gradient="success"
                                            color="white"
                                            shadow
                                            rounded
                                        ></icon>
                                    </div>
                                    <div class="pl-4">
                                        <h5 class="title text-success">
                                            Webseite - Nachhaltig?
                                        </h5>
                                        <p>
                                            Es gibt kaum etwas was sich
                                            schneller als Technologien
                                            entwickelt. Die Anforderungen an
                                            eine Webseite oder Applikation
                                            können sich schnell ändern, neue
                                            Features werden gebraucht, Ladezeit
                                            optimiert, Funktionen müssen an das
                                            Nutzerverhalten angepasst werden.
                                            <br />
                                            Die Qualität der Webseite spielt
                                            hier eine große Rolle. Für Dich
                                            entwickeln wir deswegen nachhaltig.
                                            Das bedeutet für Dich eine Webseite
                                            die mit der Zeit geht. Die ein
                                            Fundament für spätere Anforderungen
                                            liefert. An der mehrere Entwickler
                                            wenn nötig gleichzeitig arbeiten
                                            können und trotzdem die Qualität der
                                            Webseite sichergestellt ist.
                                        </p>
                                    </div>
                                </div>
                            </card>
                        </div>
                        <div class="px-2 col-lg-6 mt-5">
                            <card shadow class="shadow-lg--hover">
                                <div class="d-flex px-3">
                                    <div>
                                        <icon
                                            name="ni ni-satisfied"
                                            gradient="primary"
                                            color="white"
                                            shadow
                                            rounded
                                        ></icon>
                                    </div>

                                    <div class="pl-4">
                                        <h5 class="title text-primary">
                                            Das "more" in Web Service and more
                                            steht fürs mitdenken.
                                        </h5>

                                        <p>
                                            Für Dich blicken wir über den
                                            Tellerrand und bringen das Know How
                                            jahrelanger Arbeit mit ein.
                                        </p>
                                    </div>
                                </div>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="section section-lg"
            v-for="(feature, index) in features"
            :class="[
                isEven(index)
                    ? ''
                    : 'section-shaped my-0 overflow-hidden text-white',
            ]"
            :key="index"
        >
            <div
                v-if="!isEven(index)"
                class="shape shape-style-1 bg-gradient-warning shapes-success shape-skew"
            >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="feature-wrapper container">
                <Features
                    :id="feature.id"
                    :title="feature.title"
                    :feature="feature"
                    :ctaTitle="feature.ctaTitle"
                    :even="isEven(index)"
                    :typeStyle="isEven(index) ? 'primary' : 'success'"
                    :cardStyle="isEven(index) ? 'warning' : 'primary'"
                >
                    <LottieFullStack v-if="feature.id === 'full-stack'" />
                    <LottieEcommerce v-if="feature.id === 'ecommerce'" />
                    <LottieWordpress v-if="feature.id === 'wordpress'" />
                </Features>
            </div>
        </section>

        <!-- <section class="section section-shaped my-0 overflow-hidden text-white">


            <div
                class="
                    shape shape-style-1
                    bg-gradient-warning
                    shapes-success
                    shape-skew
                "
            >


                <span></span>


                <span></span>


                <span></span>


                <span></span>


                <span></span>


                <span></span>


                <span></span>


            </div>


            <div class="container">


                <Features
                    id="Wordpress"
                    title="Wordpress Entwicklung"
                    typeStyle="success"
                    cardStyle="success"
                ></Features>


            </div>


        </section> -->

        <!-- <section class="section section-lg">


            <div class="container">


                <Features
                    id="Ecommerce"
                    title="Ecommerce Entwicklung"
                    typeStyle="warning"
                    cardStyle="primary"
                    even
                ></Features>


            </div>


        </section> -->

        <carousel></carousel>

        <section class="section section-lg">
            <div class="container">
                <div class="row justify-content-center text-center mb-lg">
                    <div class="col-lg-8">
                        <h2 class="display-3">Als Team für Dich</h2>

                        <p class="lead text-muted">
                            So sehen insgesamt mehr als 100 Jahre Erfahrung im
                            Bereich der Webentwicklung aus.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img
                                v-lazy="'img/crew/christoph.png'"
                                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                style="width: 200px"
                            />

                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">
                                        Christoph Haaks
                                    </span>

                                    <code class="d-block min-h-3">
                                        aka Hannibal <br />(A-Team)
                                    </code>

                                    <small class="h6 text-muted">CEO</small>
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img
                                v-lazy="'img/crew/jan.png'"
                                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                style="width: 200px"
                            />

                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">Jan Kruft</span>

                                    <code class="d-block min-h-3">
                                        aka Ubuntu Fanboy
                                    </code>

                                    <small class="h6 text-muted">
                                        Full Stack Webentwickler
                                    </small>
                                </h5>

                                <div class="mt-3"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img
                                v-lazy="'img/crew/stephan.png'"
                                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                style="width: 200px"
                            />
                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">
                                        Stephan Funke
                                    </span>
                                    <code class="d-block min-h-3">
                                        aka Menu Spezialist
                                    </code>
                                    <small class="h6 text-muted">
                                        Full Stack Webentwickler
                                    </small>
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img
                                v-lazy="'img/crew/tauno.png'"
                                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                style="width: 200px"
                            />

                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1"> Tauno </span>
                                    <code class="d-block min-h-3"
                                        >aka Nightrider</code
                                    >
                                    <small class="h6 text-muted">
                                        Full Stack Webentwickler
                                    </small>
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
                        <div class="px-4">
                            <img
                                v-lazy="'img/crew/bianca.png'"
                                class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                style="width: 200px"
                            />

                            <div class="pt-4 text-center">
                                <h5 class="title">
                                    <span class="d-block mb-1">Bianca</span>

                                    <code class="d-block min-h-3">
                                        aka Frau fürs Grobe
                                    </code>

                                    <small class="h6 text-muted">
                                        macht den Rest
                                    </small>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-3 bg-gradient-default shape-skew">
                <span></span>

                <span></span>

                <span></span>

                <span></span>
            </div>

            <div class="container pt-lg pb-300">
                <div class="row text-center justify-content-center">
                    <div class="col-lg-10">
                        <h2 class="display-3 text-white">
                            Bring Deine Idee in die Welt.
                        </h2>

                        <p class="lead text-white">
                            Stell Dir vor hier steht irgendwas cooles damit Du
                            anfragst!
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="section section-lg pt-lg-0 section-contact-us"
            id="kontakt"
        >
            <div class="container">
                <KontaktForm> </KontaktForm>
            </div>
        </section>
        <!-- <section>
            <h2>Model</h2>
            {{      orginX }}

            <model-viewer :modelAddress="model.modelAddress"
                            :viewWidth="model.viewWidth"
                            :viewHeight="model.viewHeight"
                            :originX="model.originX"
                            >
        </model-viewer>
      </section> -->
    </div>
</template>

<script>
import Carousel from './components/Carousel'
import LottieWebDev from './components/LottieComp'
import LottieFullStack from './components/LottieFullStack'
import Technologien from './components/TechnologienIcons.vue'
import Features from './components/Features'
// import modelViewer from './components/ModelViewer'
import poster from '/public/img/wsam-logo.png'
import KontaktForm from './components/KontaktForm.vue'
import LottieEcommerce from './components/LottieEcommerce.vue'
import LottieWordpress from './components/LottieWordpress.vue'

export default {
    name: 'home',
    components: {
        Carousel,
        LottieWebDev,
        LottieFullStack,
        Technologien,
        Features,
        KontaktForm,
        // modelViewer
        KontaktForm,
        LottieEcommerce,
        LottieWordpress,
    },
    data() {
        return {
            orginX: 1,
            model: {
                modelAddress: '',
                width: 300,
                height: 300,
                poster: poster,
                src: './models/test.glb',
                viewWidth: '500px',

                viewHeight: '500px',
            },

            features: [
                {
                    title: 'Full Stack Entwicklung',
                    id: 'full-stack',
                    typeStyle: 'success',
                    image: '/public/img/full-stack.png',
                    description:
                        'Deine Idee "from Scratch" umsetzen oder Deine bestehende Anwendung erweitern. Wir arbeiten mit  Mysql, MongoDB, PostgreSQL, Php, Laravel, Symfony, NodeJS, VueJs oder React. Gerne helfen wir bei der Auswahl des richtigen "Stacks" oder verbessern und erweitern deine bestehende Webapplikation.',
                    ctaTitle: 'Damit Deine Idee in die virtuelle Welt kommt',
                    usps: [
                        {
                            text: 'Sorgfältige Planung Deiner Anwendungen - Von der Auswahl der richtigen Technologie über Prototype Entwicklung bis zur Enterprise Anwendung and more.',
                            icon: 'ni ni-settings-gear-65',
                        },
                        {
                            text: 'skalierbar, moderne Technologien, Coding Standards and more.',
                            icon: 'ni ni-html5',
                        },
                        {
                            text: 'Agentur Unterstützung - Qualitätssicherung, Workflow Optimierung, Testing Integration, oder Last Spitzen die wir abfedern and more.',
                            icon: 'ni ni-satisfied',
                        },
                    ],
                },
                {
                    title: 'Wordpress Entwicklung',
                    id: 'wordpress',
                    icon: '',
                    image: '/public/img/full-stack.png',
                    ctaTitle:
                        'Starte mit Wordpress dem beliebtesten Content Management System durch',
                    description:
                        'Du kennst Dich mit dem Content Management System Wordpress aus oder möchtest deine Webseite gerne auf dem beliebten System haben? Super! Seit über 10 Jahren unterstützen wir Unternehmer und Unternehmen mit maßgeschneiderten Wordpress Lösungen.',
                    usps: [
                        {
                            text: 'Wordpress Webseite - einfach zu bedienen and more',
                            icon: 'ni ni-settings-gear-65',
                        },
                        {
                            text: 'Ein auf deinen Bedarf zugeschnittenes Theme oder Plugin and more',
                            icon: 'ni ni-html5',
                        },
                        {
                            text: 'Die Performance deiner Webseite verbessern, Plugins aktualisieren and more',
                            icon: 'ni ni-satisfied',
                        },
                    ],
                },
                {
                    title: 'Ecommerce Entwicklung',
                    id: 'ecommerce',
                    ctaTitle:
                        'Deine Kunden haben die beste Online Shopping Erfahrung verdient!',
                    description:
                        'Einen Konfigurator für deinen bestehenden Online Shop , Optimierung deines Verkaufskanals oder Planung und Erstellung Deines eigenen 24/7 Verkaufskanals. Wir arbeiten mit bewährten Online Shopsystemen oder bauen individuelle Lösungen.',
                    usps: [
                        {
                            text: 'Sorgfältige Planung Deines Online Shops and more',
                            icon: 'ni ni-settings-gear-65',
                        },
                        {
                            text: 'skalierbar, moderne Technologien, Coding Standards und auf deine Kunden zugeschnitten and more',
                            icon: 'ni ni-html5',
                        },
                        {
                            text: 'Schnittstellen einrichten und Verbindung zur Warenwirtschaft, Buchhaltung, Marketing Tools and more',
                            icon: 'ni ni-satisfied',
                        },
                    ],
                },
            ],
        }
    },
    methods: {
        focusNameInput(e) {
            e.preventDefault()
            const el = this.$refs.nameInput.$el
            el.scrollIntoView({ behavior: 'smooth' })
            el.focus()
        },
        handleScroll(event) {
            this.originX = window.screenY
        },
        isEven(n) {
            return n % 2 == 0
        },
    },
    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focused = true
            },
        },
    },
    mounted() {
        this.model.modelAddress = this.model.src
    },
}
</script>
